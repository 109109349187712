import server from '../request';

const getCompanyInfo = (keyWord)=> {
  return server.post('/xiaozi-xmo/wireless/saas/employee/qcc/page', {keyWord, pageNum: 1});
};

// 企业库分页列表
const enterpriseList = (data) => server.post('/xiaozi-xmo/wireless/miniapp/tenant/enterprise/page', data);

// 创建企业
const createTenant = (data) => server.post('/xiaozi-xmo/wireless/miniapp/user/create/tenant', {...data, registerSource: 2}, { timeout: 45000 });

// 企业基本信息
const tenantBaseInfo = (data) => server.post('/xiaozi-xmo/wireless/miniapp/tenant/information/baseinfo', data);

// 企业详情
const companyDetail = (data) => server.post('xiaozi-xmo/wireless/miniapp/tenant/detail', data);

// 企业入驻校验是否是小紫供应商(返回值name有值代表已经是小紫合作供应商)
const check = (data) => server.post('/xiaozi-xmo/wireless/miniapp/tenant/information/check', data);

// 企业认证
const authenticate = (data) => server.post('/xiaozi-xmo/wireless/miniapp/tenant/authentication', data);

// 企业更新（完善企业介绍，公司规模，经营类型,企业logo，企业名称五个信息）
const updateTenant = (data) => server.post('/xiaozi-xmo/wireless/miniapp/tenant/update', data);

export {
  getCompanyInfo,
  enterpriseList,
  createTenant,
  tenantBaseInfo,
  companyDetail,
  check,
  authenticate,
  updateTenant,
};
