import { updateTenant } from '@/api/company';
import {getDetailTenantInfo} from '@/api/systemSetting';
import Upload from '@/components/common/upload';
import AccreditationDialog from '@/views/SystemSettings/company/component/accreditationDialog';
let AvaterMixin = {
  components: {
    Upload,
    AccreditationDialog,
  },
  created () {
    this.getTenantBaseInfo();
  },
  data () {
    return {
      companyInfo: {},
      imageLists: [],
    };
  },
  methods: {
    handleUpload () {
      this.$refs.JZUpload.$el.querySelector('input').click();
    },
    handleRemove () {
      console.log(arguments[0]);
    },
    handleFileLists (files) {
      this.imageLists = [];
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      let companyInfo = this.companyInfo;
      let currentParams = {
        tenantId: userInfo.tenantId,
        logo: files.url,
        businessType: companyInfo.businessType,
        name: companyInfo.name,
        remark: companyInfo.remark,
        scale: companyInfo.scale,
      };

      updateTenant(currentParams).then(()=>{
        this.imageLists = [];
        this.getTenantBaseInfo();
      });
    },
    async getTenantBaseInfo () {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      const { body } = await getDetailTenantInfo({id: userInfo.tenantId});
      this.$store.dispatch('UPDATE_COMPANYINFO', body);
      this.companyInfo = body;
    },
    // 立即认证
    goToCertification () {
      if (['1'].includes(this.$store.state.company.currentCompanyInfo.authenticationStatus)) {
        this.$refs.JZAccreditationDialog.initPage();
      } else {
        this.$router.push({
          name: 'IDENTIFICATION',
        });
      }
    },
  },
};
export default AvaterMixin;
